export const PlanActionTypes = {
  FETCH_PLAN_REQUEST: "@@Plan/FETCH_PLAN_REQUEST",
  FETCH_PLAN_SUCCESS: "@@Plan/FETCH_PLAN_SUCCESS",
  FETCH_PLAN_FAILURE: "@@Plan/FETCH_PLAN_FAILURE",

  FETCH_PLANS_REQUEST: "@@Plan/FETCH_PLANS_REQUEST",
  FETCH_PLANS_SUCCESS: "@@Plan/FETCH_PLANS_SUCCESS",
  FETCH_PLANS_FAILURE: "@@Plan/FETCH_PLANS_FAILURE",

  FETCH_PLAN_MEMBER_REQUEST: "@@Plan/FETCH_PLAN_MEMBER_REQUEST",
  FETCH_PLAN_MEMBER_SUCCESS: "@@Plan/FETCH_PLAN_MEMBER_SUCCESS",
  FETCH_PLAN_MEMBER_FAILURE: "@@Plan/FETCH_PLAN_MEMBER_FAILURE",

  CREATE_PLAN_MEMBER_REQUEST: "@@Plan/CREATE_PLANS_REQUEST",
  CREATE_PLAN_MEMBER_SUCCESS: "@@Plan/CREATE_PLANS_SUCCESS",
  CREATE_PLAN_MEMBER_FAILURE: "@@Plan/CREATE_PLANS_FAILURE",
};

