import { action } from "typesafe-actions";
import { TransactionActionTypes } from "./transaction.model";

export const fetchBuyRequest = (req) => action(TransactionActionTypes.FETCH_BUY_REQUEST, req);
export const fetchBuySuccess = (res) => action(TransactionActionTypes.FETCH_BUY_SUCCESS, res);
export const fetchBuyFailure = (err) => action(TransactionActionTypes.FETCH_BUY_FAILURE, err);

export const fetchPayoutRequest = (req) => action(TransactionActionTypes.FETCH_PAYOUT_REQUEST, req);
export const fetchPayoutSuccess = (res) => action(TransactionActionTypes.FETCH_PAYOUT_SUCCESS, res);
export const fetchPayoutFailure = (err) => action(TransactionActionTypes.FETCH_PAYOUT_FAILURE, err);

export const fetchSellRequest = (req) => action(TransactionActionTypes.FETCH_SELL_REQUEST, req);
export const fetchSellSuccess = (res) => action(TransactionActionTypes.FETCH_SELL_SUCCESS, res);
export const fetchSellFailure = (err) => action(TransactionActionTypes.FETCH_SELL_FAILURE, err);

export const fetchWithdrawRequest = (req) => action(TransactionActionTypes.FETCH_WITHDRAW_REQUEST, req);
export const fetchWithdrawSuccess = (res) => action(TransactionActionTypes.FETCH_WITHDRAW_SUCCESS, res);
export const fetchWithdrawFailure = (err) => action(TransactionActionTypes.FETCH_WITHDRAW_FAILURE, err);

export const createBuyRequest = (req) => action(TransactionActionTypes.CREATE_BUY_REQUEST, req);
export const createBuySuccess = (res) => action(TransactionActionTypes.CREATE_BUY_SUCCESS, res);
export const createBuyFailure = (err) => action(TransactionActionTypes.CREATE_BUY_FAILURE, err);

export const createSellRequest = (req) => action(TransactionActionTypes.CREATE_SELL_REQUEST, req);
export const createSellSuccess = (res) => action(TransactionActionTypes.CREATE_SELL_SUCCESS, res);
export const createSellFailure = (err) => action(TransactionActionTypes.CREATE_SELL_FAILURE, err);

export const createWithdrawRequest = (req) => action(TransactionActionTypes.CREATE_WITHDRAW_REQUEST, req);
export const createWithdrawSuccess = (res) => action(TransactionActionTypes.CREATE_WITHDRAW_SUCCESS, res);
export const createWithdrawFailure = (err) => action(TransactionActionTypes.CREATE_WITHDRAW_FAILURE, err);

export const processPayoutRequest = (req) => action(TransactionActionTypes.PROCESS_PAYOUT_REQUEST, req);
export const processPayoutSuccess = (res) => action(TransactionActionTypes.PROCESS_PAYOUT_SUCCESS, res);
export const processPayoutFailure = (err) => action(TransactionActionTypes.PROCESS_PAYOUT_FAILURE, err);




