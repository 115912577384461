export const UserActionTypes = {
  REGISTER_USER_REQUEST: "@@User/REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "@@User/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "@@User/REGISTER_USER_FAILURE",

  FETCH_USER_REQUEST: "@@User/FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "@@User/FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "@@User/FETCH_USER_FAILURE",

  FETCH_USERS_REQUEST: "@@User/FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS: "@@User/FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE: "@@User/FETCH_USERS_FAILURE",

  UPDATE_USER_REQUEST: "@@User/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "@@User/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "@@User/UPDATE_USER_FAILURE",

  UPDATE_NOMINEE_REQUEST: "@@User/UPDATE_NOMINEE_REQUEST",
  UPDATE_NOMINEE_SUCCESS: "@@User/UPDATE_NOMINEE_SUCCESS",
  UPDATE_NOMINEE_FAILURE: "@@User/UPDATE_NOMINEE_FAILURE",

  FETCH_REFERAL_REQUEST: "@@User/FETCH_REFERAL_REQUEST",
  FETCH_REFERAL_SUCCESS: "@@User/FETCH_REFERAL_SUCCESS",
  FETCH_REFERAL_FAILURE: "@@User/FETCH_REFERAL_FAILURE",

  FETCH_REFEREE_REQUEST: "@@User/FETCH_REFEREE_REQUEST",
  FETCH_REFEREE_SUCCESS: "@@User/FETCH_REFEREE_SUCCESS",
  FETCH_REFEREE_FAILURE: "@@User/FETCH_REFEREE_FAILURE",

  FETCH_WALLET_REQUEST: "@@User/FETCH_WALLET_REQUEST",
  FETCH_WALLET_SUCCESS: "@@User/FETCH_WALLET_SUCCESS",
  FETCH_WALLET_FAILURE: "@@User/FETCH_WALLET_FAILURE",

  WITHDRAW_WALLET_REQUEST: "@@User/WITHDRAW_WALLET_REQUEST",
  WITHDRAW_WALLET_SUCCESS: "@@User/WITHDRAW_WALLET_SUCCESS",
  WITHDRAW_WALLET_FAILURE: "@@User/WITHDRAW_WALLET_FAILURE",

  CHANGE_2FA_REQUEST: "@@User/CHANGE_2FA_REQUEST",
  CHANGE_2FA_SUCCESS: "@@User/CHANGE_2FA_SUCCESS",
  CHANGE_2FA_FAILURE: "@@User/CHANGE_2FA_FAILURE",

  CHANGE_PIN_REQUEST: "@@User/CHANGE_PIN_REQUEST",
  CHANGE_PIN_SUCCESS: "@@User/CHANGE_PIN_SUCCESS",
  CHANGE_PIN_FAILURE: "@@User/CHANGE_PIN_FAILURE",
};

