import { call, put, takeEvery } from 'redux-saga/effects';
import * as TransactionActions from './transaction.actions';
import TransactionAPI from './transaction.api';
import { TransactionActionTypes } from './transaction.model';
import Swal from "sweetalert2";
import { fetchPlanMemberRequest } from '../plan/plan.actions';
import { fetchUserWalletRequest } from '../user/user.actions';

export function* handleBuyCreate(action) {
  try {
    const response = yield call(TransactionAPI.CreateBuyTransaction, action.payload);
    yield put(TransactionActions.createBuySuccess(response));
    yield put(TransactionActions.fetchBuyRequest("Approved"));
    yield put(fetchUserWalletRequest());
    Swal.fire({
      title: "Buy Request",
      text: "Tokens bought successfully",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(TransactionActions.createBuyFailure(e));
  }
}

export function* handleSellCreate(action) {
  try {
    const response = yield call(TransactionAPI.CreateSellTransaction, action.payload);
    yield put(TransactionActions.createSellSuccess(response));
    yield put(TransactionActions.fetchSellRequest("Approved"));
    yield put(fetchUserWalletRequest());
    Swal.fire({
      title: "Sell Request",
      text: "Tokens Sold successfully",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(TransactionActions.createSellFailure(e));
  }
}

export function* handlePayoutCreate(action) {
  try {
    const response = yield call(TransactionAPI.ProcessPayoutTransactions, action.payload);
    yield put(TransactionActions.processPayoutSuccess(response));
    yield put(TransactionActions.fetchPayoutRequest());
    yield put(fetchPlanMemberRequest());
    Swal.fire({
      title: "Payout Request",
      text: "Coins sent to your wallet successfully",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(TransactionActions.processPayoutFailure(e));
  }
}

export function* handleWithdrawCreate(action) {
  try {
    const response = yield call(TransactionAPI.CreateWithdrawTransaction, action.payload);
    yield put(TransactionActions.createWithdrawSuccess(response));
    yield put(TransactionActions.fetchWithdrawRequest("Approved"));
    Swal.fire({
      title: "Withdraw Request",
      text: "Coins withdrawn successfully",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(TransactionActions.fetchWithdrawFailure(e));
  }
}

export function* handleBuyTransactions(action) {
  try {
    const response = yield call(TransactionAPI.GetBuyTransactions, action.payload);
    yield put(TransactionActions.fetchBuySuccess(response));
  } catch (e) {
    yield put(TransactionActions.fetchBuyFailure(e));
  }
}

export function* handleSellTransactions(action) {
  try {
    const response = yield call(TransactionAPI.GetSellTransactions, action.payload);
    yield put(TransactionActions.fetchSellSuccess(response));
  } catch (e) {
    yield put(TransactionActions.fetchSellFailure(e));
  }
}

export function* handleWithdrawTransactions(action) {
  try {
    const response = yield call(TransactionAPI.GetWithdrawTransactions, action.payload);
    yield put(TransactionActions.fetchWithdrawSuccess(response));
  } catch (e) {
    yield put(TransactionActions.fetchWithdrawFailure(e));
  }
}

export function* handlePayoutTransactions(action) {
  try {
    const response = yield call(TransactionAPI.GetPayoutTransactions, action.payload);
    yield put(TransactionActions.fetchPayoutSuccess(response));
  } catch (e) {
    yield put(TransactionActions.fetchPayoutFailure(e));
  }
}

export function* TransactionSaga() {
  yield takeEvery(TransactionActionTypes.CREATE_BUY_REQUEST, handleBuyCreate);
  yield takeEvery(TransactionActionTypes.CREATE_SELL_REQUEST, handleSellCreate);
  yield takeEvery(TransactionActionTypes.CREATE_WITHDRAW_REQUEST, handleWithdrawCreate);
  yield takeEvery(TransactionActionTypes.FETCH_BUY_REQUEST, handleBuyTransactions);
  yield takeEvery(TransactionActionTypes.FETCH_SELL_REQUEST, handleSellTransactions);
  yield takeEvery(TransactionActionTypes.FETCH_WITHDRAW_REQUEST, handleWithdrawTransactions);
  yield takeEvery(TransactionActionTypes.PROCESS_PAYOUT_REQUEST, handlePayoutCreate);
  yield takeEvery(TransactionActionTypes.FETCH_PAYOUT_REQUEST, handlePayoutTransactions);
}

