export function getPlans(state) {
    return state.PlanReducer.plans;
}

export function getPlanDetail(state) {
    return state.PlanReducer.plan;
}

export function getPlanMembers(state) {
    return state.PlanReducer.planMembers;
}