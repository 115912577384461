export function getBuyRequests(state) {
    return state.TransactionReducer.buyRequests;
}

export function getSellRequests(state) {
    return state.TransactionReducer.sellRequests;
}

export function getWithdrawRequests(state) {
    return state.TransactionReducer.withdrawRequests;
}

export function getPayoutRequests(state) {
    return state.TransactionReducer.payouts;
}

export function isTransactionLoading(state) {
    return state.TransactionReducer.isLoading;
}