import { TransactionActionTypes } from './transaction.model';
require('dotenv').config();

export const initialState = {
    buyRequests: [],
    sellRequests: [],
    withdrawRequests: [],
    payouts: [],
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TransactionActionTypes.CREATE_BUY_REQUEST:
        case TransactionActionTypes.PROCESS_PAYOUT_REQUEST:
        case TransactionActionTypes.CREATE_SELL_REQUEST:
        case TransactionActionTypes.CREATE_WITHDRAW_REQUEST:
        case TransactionActionTypes.FETCH_BUY_REQUEST:
        case TransactionActionTypes.FETCH_SELL_REQUEST:
        case TransactionActionTypes.FETCH_PAYOUT_REQUEST:
        case TransactionActionTypes.FETCH_WITHDRAW_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case TransactionActionTypes.CREATE_BUY_SUCCESS:
        case TransactionActionTypes.CREATE_SELL_SUCCESS:
        case TransactionActionTypes.CREATE_WITHDRAW_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case TransactionActionTypes.FETCH_BUY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                buyRequests: action.payload
            };
        }

        case TransactionActionTypes.FETCH_PAYOUT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                payouts: action.payload
            };
        }

        case TransactionActionTypes.FETCH_SELL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                sellRequests: action.payload
            };
        }

        case TransactionActionTypes.FETCH_WITHDRAW_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                withdrawRequests: action.payload
            };
        }

        case TransactionActionTypes.CREATE_BUY_FAILURE:
        case TransactionActionTypes.CREATE_SELL_FAILURE:
        case TransactionActionTypes.CREATE_WITHDRAW_FAILURE:
        case TransactionActionTypes.PROCESS_PAYOUT_FAILURE:
        case TransactionActionTypes.FETCH_PAYOUT_FAILURE:
        case TransactionActionTypes.FETCH_BUY_FAILURE:
        case TransactionActionTypes.FETCH_SELL_FAILURE:
        case TransactionActionTypes.FETCH_WITHDRAW_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as TransactionReducer };