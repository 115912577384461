import { Box, Button, FormControlLabel, Grid, InputAdornment, Radio, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { getWalletInformation } from '../../../store/user/user.selector';

export default function WithdrawRequest(props) {
    const { onWithdraw } = props;
    const [withdrawMode, setWithdrawMode] = useState("BNB");
    const [transferAmount, setTransferAmount] = useState(0);
    const [transactionCharge, setTransactionCharge] = useState(0);

    const { walletInfo } = useSelector((state) => {
        return {
            walletInfo: getWalletInformation(state),
        }
    }, []);

    let oneUSDBNB = parseFloat(1 / walletInfo.coinPrice);
    let oneT369BNB = parseFloat(walletInfo.tokenPrice.price / walletInfo.coinPrice);
    let oneBNBPriceInUSD = parseFloat(walletInfo.coinPrice);
    let oneT369PriceInUSD = parseFloat(walletInfo.tokenPrice.price);

    useEffect(() => {
        if (withdrawMode === "BNB") {
            setTransferAmount(walletInfo.coinBalance);
        }
        else if (withdrawMode === "T369") {
            setTransferAmount(walletInfo.tokenBalance);
        }
        else {
            setTransferAmount(walletInfo.usdtTokenBalance);
        }
    }, [walletInfo, withdrawMode]);

    useEffect(() => {
        if (withdrawMode === "BNB") {
            setTransactionCharge((parseFloat(transferAmount) * 1 / 100).toFixed(8));
        }
        else if (withdrawMode === "T369") {
            setTransactionCharge((parseFloat(transferAmount) * oneT369BNB * 1 / 100).toFixed(8));
        }
        else {
            setTransactionCharge((parseFloat(transferAmount) * oneUSDBNB * 1 / 100).toFixed(8));
        }
    }, [transferAmount])

    const handleWithdraw = () => {
        let error = "";
        if (withdrawMode === "USDT" && parseFloat(transferAmount) < 10) {
            error = "Minimum Withdraw amount is 10 USDT";
        }
        else if (withdrawMode === "USDT" && parseFloat(transferAmount) >= 10) {
            if (parseFloat(walletInfo.usdtTokenBalance) < parseFloat(transferAmount)) {
                error = "You don't have sufficient USDT balance to withdraw";
            }
            if (parseFloat(walletInfo.coinBalance) < parseFloat(transactionCharge)) {
                error = "You don't have sufficient BNB balance to process";
            }
        }
        else if (withdrawMode === "T369") {
            let totalT369InUSD = parseFloat(transferAmount) * oneT369PriceInUSD;
            if (parseFloat(totalT369InUSD) < 10) {
                error = `Minimum Withdraw amount is  ${(10 / oneT369PriceInUSD).toFixed(2)} T369`;
            }
            else if (parseFloat(walletInfo.tokenBalance) < parseFloat(transferAmount)) {
                error = "You don't have sufficient T369 balance to withdraw";
            }
            else if (parseFloat(walletInfo.coinBalance) < parseFloat(transactionCharge)) {
                error = "You don't have sufficient BNB balance to process";
            }
        }
        else {
            let totalBNBInUSD = parseFloat(transferAmount) * oneBNBPriceInUSD;
            if (parseFloat(totalBNBInUSD) < 10) {
                error = `Minimum Withdraw amount is  ${(10 / oneBNBPriceInUSD).toFixed(8)} BNB`;
            }
            else if (parseFloat(walletInfo.coinBalance) < parseFloat(transferAmount)) {
                error = "You don't have sufficient BNB balance to withdraw";
            }
        }

        if (error) {
            Swal.fire({
                icon: "error",
                title: "Validation",
                text: error
            })
        }
        else {
            onWithdraw(withdrawMode, parseFloat(transferAmount) - (2 * parseFloat(transactionCharge)));
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControlLabel
                        value="start"
                        control={<Radio color="primary" checked={withdrawMode === "BNB"} />}
                        label={`Using BNB (${walletInfo.coinBalance})`}
                        labelPlacement="end"
                        onChange={(e) => {
                            if (e.target.checked) {
                                setWithdrawMode("BNB");
                            }
                        }}
                    />
                    <FormControlLabel
                        value="start"
                        control={<Radio color="primary" checked={withdrawMode === "USDT"} />}
                        label={`Using USDT (${walletInfo.usdtTokenBalance})`}
                        labelPlacement="end"
                        onChange={(e) => {
                            if (e.target.checked) {
                                setWithdrawMode("USDT");
                            }
                        }}
                    />
                    <FormControlLabel
                        value="start"
                        control={<Radio color="primary" checked={withdrawMode === "T369"} />}
                        label={`Using T369 (${walletInfo.tokenBalance})`}
                        labelPlacement="end"
                        onChange={(e) => {
                            if (e.target.checked) {
                                setWithdrawMode("T369");
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        className='my-1'
                        margin='dense'
                        variant='outlined'
                        value={transferAmount}
                        onChange={(e) => {
                            let curValue = e.target.value;
                            if (!curValue) {
                                curValue = 0;
                            }
                            setTransferAmount(curValue);
                        }}
                        placeholder='Enter Value'
                        label={"Amount"}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                {withdrawMode}
                            </InputAdornment>
                        }}
                    />
                    <TextField
                        fullWidth
                        className='my-1'
                        margin='dense'
                        variant='outlined'
                        value={transactionCharge}
                        placeholder='Enter Value'
                        label={"Transaction Charge"}
                        disabled
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                BNB
                            </InputAdornment>
                        }}
                    />

                    <Button className='mt-2' onClick={() => {
                        handleWithdraw()
                    }} variant='contained' color="primary" fullWidth>
                        Withdraw {withdrawMode}
                    </Button>

                </Grid>
            </Grid>
        </Box>
    );
}

