import { Badge, Card, CardContent, CardHeader, Container, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { ChevronRight, ExpandMore, FileCopy } from '@material-ui/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TreeItem, TreeView } from '@material-ui/lab';
import MUIDataTable from 'mui-datatables';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { unflatten } from '../../helpers/utility';
import { fetchUserReferralRequest } from '../../store/user/user.actions';
import { getUserReferrals } from '../../store/user/user.selector';
import TitleBar from '../base/TitleBar';
import moment from 'moment';

export default function RefereeContainer() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserReferralRequest())
    }, [])

    const { referals } = useSelector((state) => {
        return {
            referals: getUserReferrals(state)
        }
    }, [])



    let data = _.sortBy(referals.children, "depth");
    data = _.groupBy(data, "depth");

    return (
        <div>
            <TitleBar heading="Referrals" secondary={""}
                icon={<HelpOutlineIcon />} />
            <div className='content-height m-2 p-2'>
                {
                    Object.keys(data).map((x) => {
                        const columns = ["Member #", "Name", "Mobile Number", "Plans", "Activated On"];

                        const datum = data[x].map((y) => {
                            let value = referals && referals.planMember &&
                                referals.planMember.filter((t) => {
                                    return t.memberId === y.memberId
                                });
                            let latestDate = "";
                            if (!value || value.length === 0) {
                                latestDate = "Not Activated";
                            }
                            else {
                                if (value.length > 1) {
                                    latestDate = _.sortBy(value, t => moment(t.createdAt).unix());
                                    latestDate = latestDate[0].createdAt;
                                    latestDate = moment(latestDate).format("LLL")
                                }
                                else {
                                    latestDate = value[0].createdAt;
                                    latestDate = moment(latestDate).format("LLL")
                                }
                            }
                            let totalPlans = (referals.planMember && referals.planMember.filter((t) => {
                                return t.memberId === y.memberId
                            }).length) || 0
                            return [y.memberId, y.firstName + " " + y.lastName, y.mobileNumber.replace(/\D/g, '').substr(0, 5) + "XXXXX", totalPlans, latestDate];
                        });

                        const options = {
                            selectableRows: false,
                            download: false,
                            print: false,
                            rowsPerPage: 100

                        };
                        return <Card elevation={10} className="mb-2">
                            <CardHeader title={"Level " + (parseInt(x) + 1)} subheader="Member Information" />
                            <Divider />
                            <CardContent>
                                <MUIDataTable
                                    title={"Member List"}
                                    data={datum}
                                    columns={columns}
                                    options={options}
                                />
                            </CardContent>
                        </Card>
                    })
                }
            </div >
        </div >
    );
}

