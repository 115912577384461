import { Box, InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../store/user/user.selector';
import ReactQRCode from "react-qr-code";
import * as Toastr from "toastr";
import { FileCopy } from '@material-ui/icons';

export default function DepositRequest(props) {
    const { currentUser } = useSelector((state) => {
        return {
            currentUser: getCurrentUser(state)
        }
    }, []);

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <ReactQRCode value={currentUser.walletAddress} />
            <p className='my-2'>Scan the QR Code from your app and deposit BNB / USDT</p>
            <TextField
                disabled
                fullWidth
                variant='outlined'
                margin='dense'
                value={currentUser.walletAddress}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        <FileCopy color="primary" onClick={() => {
                            navigator.clipboard.writeText(currentUser.walletAddress);
                            Toastr.info("Address copied to clipboard");
                        }} />
                    </InputAdornment>
                }}
            />
        </Box>
    );
}

