import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { AddCircle, Close, CloudDownload, List, OpenInNew } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { getPlanDetail, getPlanMembers, getPlans } from '../../store/plan/plan.selector';
import { downloadIllustrationReport } from '../../helpers/PDFGenerator';
import { getTransactionUrl } from '../../helpers/utility';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function MyPlanDetail(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});

    const { plans, planDetail, planMembers } = useSelector((state) => {
        return {
            plans: getPlans(state),
            planDetail: getPlanDetail(state),
            planMembers: getPlanMembers(state)
        }
    }, []);


    let currency = "USD";

    return (
        <Grid container spacing={2}>
            <Dialog maxWidth="md" fullWidth open={open}>
                <DialogTitle>
                    Plan Details <Close className='float-right' onClick={() => {
                        setOpen(false)
                    }} />
                </DialogTitle>
                <Divider />
                <DialogContent>

                    <h4 className='my-1'>Plan Summary</h4>
                    {
                        selectedPlan &&
                        <table className='table table-striped mt-2'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Reward</th>
                                    <th>Net Reward</th>
                                    <th>Reinvesment</th>
                                    <th>Liquidity</th>
                                    <th>Gas Fees</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedPlan.returns && selectedPlan.returns.map((s, index) => {
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>{s.date}</td>
                                        <td>{s.amount} {currency}</td>
                                        <td>{s.interest} {currency}</td>
                                        <td>{s.withdrawalAmount} {currency}</td>
                                        <td>{s.reinvest} {currency}</td>
                                        <td>{s.liquidity} {currency}</td>
                                        <td>{s.adminFees} {currency}</td>
                                        <td>
                                            {s.status}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    }
                    <Divider />
                    <h4 className='my-1'>Payouts</h4>
                    {
                        selectedPlan && selectedPlan.payouts &&
                        <table className='table table-striped mt-2'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Reward</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedPlan.payouts && selectedPlan.payouts.map((s, index) => {
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>{s.type}</td>
                                        <td>{moment(s.createdAt).format("LLL")}</td>
                                        <td>{s.amount} {currency}</td>
                                        <td>{s.createdBy}</td>
                                        <td>{s.memberId}</td>
                                        <td>
                                            {s.status}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    }
                </DialogContent>
                <DialogActions>
                    <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        {
                            selectedPlan.contract && selectedPlan.contract[0] &&
                            <a target='_blank' href={getTransactionUrl("BNB") + selectedPlan.contract[0].transactionHash}>
                                Transaction Hash <OpenInNew color="primary" />
                            </a>
                        }
                        <Button variant='contained' startIcon={<CloudDownload />} color="secondary" onClick={() => {
                            downloadIllustrationReport(selectedPlan.returns)
                        }}>
                            Download
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Grid item md={4} lg={4} sm={12} xs={12}>
                <Card onClick={() => {
                    props.onOpen()
                }} variant='outlined' style={{ minHeight: 250 }}>
                    <CardContent>
                        <Box display="flex" flexDirection={"column"} height={250} alignItems={"center"} justifyContent={"center"}>
                            <AddCircle style={{ fontSize: 64 }} color='secondary' />
                            <p className='text-secondary text-bold'>Create Plan</p>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            {
                planMembers && planMembers.map((s, index) => {
                    let planDetail = _.find(plans, z => z._id === s.planId);
                    let duration = moment(s.maturityDate).diff(moment(s.createdAt), "day");
                    let completed = s.status === "Approved" ? moment().diff(moment(s.createdAt), "day") : duration
                    completed = parseInt(completed * 100 / duration);
                    return <Grid item md={4} lg={4} sm={12} xs={12}>
                        <Card variant='outlined'>
                            <CardHeader
                                avatar={<List />}
                                title={planDetail && planDetail.planName}
                                subheader={planDetail && planDetail.duration + " months"}
                                action={<Button size="small" onClick={() => {
                                    setOpen(true);
                                    setSelectedPlan(s);
                                }} className='mt-3' variant="contained" color="secondary">
                                    Details
                                </Button>}
                            />
                            <Divider />
                            <CardContent style={{ padding: 8 }}>
                                <ul className='stripe-ul'>
                                    <li>
                                        <span>Joined Date :</span>
                                        <span>{moment(s.createdAt).format("lll")}</span>
                                    </li>
                                    <li>
                                        <span>{s.status === "Approved" ? "Maturity Date" : "Unstack Date"} :</span>
                                        <span>{moment(s.maturityDate).format("ll")}</span>
                                    </li>
                                    <li>
                                        <span>Duration : </span>
                                        <span>{s.returns && s.returns.length} Months</span>
                                    </li>
                                    <li>
                                        <span>Amount : </span>
                                        <span>{s.amount} USD</span>
                                    </li>
                                    <li>
                                        <span>Tokens : </span>
                                        <span>{s.token} T369</span>
                                    </li>
                                </ul>
                                <LinearProgress className='mt-2' color="primary" style={{ height: 20 }} value={completed} variant="determinate" />
                            </CardContent>
                        </Card>
                    </Grid>
                })
            }
        </Grid>
    );
}

