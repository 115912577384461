import { Avatar, Box, Button, Card, CardContent, CardHeader, Container, Divider, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Close, FileCopy, MobileFriendly } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { IsNullOrEmpty, clearToken, decodeToken } from '../../helpers/utility';
import { getAccessToken } from '../../store/authentication/auth.selector';
import UserAPI from '../../store/user/user.api';
import OTPVerifyEmail from './otp-verify-email';
import OTPVerifyPhone from './otp-verify-phone';
import VerifyPin from './verifyPin';
import * as Toastr from "toastr";
import UpdateInfo from './updateEmail';
import OTPInput from 'react-otp-input';
import Loader from '../base/loader';

export default function OTPVerify() {
  const [QRCode, SetQRCode] = useState("");
  const [QRCodePin, SetQRCodePin] = useState("");
  const [mode, setMode] = useState("");
  const [pinMode, setPinMode] = useState("PIN");
  const [isLoading, setLoading] = useState(false);
  const [pin, setPIN] = useState("");
  const [cPin, setCPin] = useState("");

  const { accessToken } = useSelector((state) => {
    return {
      accessToken: getAccessToken(state),
    }
  })

  useEffect(() => {
    if (!IsNullOrEmpty(accessToken)) {
      const user = decodeToken(accessToken);
      if (user.isCompanyAccount) {
        setMode("Company");
      }
      else if (!user.isEmailVerified) {
        UserAPI.SetEmailOTP();
        setMode("Email");
      }
      else if (!user.isMobileVerified) {
        UserAPI.SetPhoneOTP();
        setMode("Phone");
      }
      else if (user.firstTimeUser) {
        UserAPI.Get2FASetUpCode().then((s) => {
          SetQRCode(s.url);
          SetQRCodePin(s.secret);
        })
        setMode("2FA");
      }
    }
  }, [accessToken]);

  return (
    <>
      {
        isLoading && <Loader />
      }
      {
        mode === "Email" ? <OTPVerifyEmail
          onComplete={() => setMode("Phone")} onClose={() => {
            clearToken();
          }} /> :
          mode === "Phone" ? <OTPVerifyPhone
            onComplete={() => setMode("2FA")} onClose={() => {
              clearToken();
            }} /> :
            mode === "Company" ? <UpdateInfo
              onComplete={() => setMode("Phone")} onClose={() => {
                clearToken();
              }} /> :
              <Container maxWidth="sm" className='mt-4 pt-4'>
                <Card elevation={10}>
                  <CardHeader
                    avatar={<Avatar>
                      <MobileFriendly />
                    </Avatar>}
                    title={"2FA Setup"} subheader={pinMode === "PIN" ? "6 Digit User Pin" : "6 Digit Authenticator Code"}
                    action={<Close onClick={() => clearToken()} />}
                  />
                  <Divider />
                  <CardContent>
                    <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">2FA Type</FormLabel>
                        <RadioGroup row aria-label="gender" name="gender1" value={pinMode} onChange={(e) => setPinMode(e.target.value)}>
                          <FormControlLabel value="PIN" control={<Radio />} label="User Pin" />
                          <FormControlLabel value="2FA" control={<Radio />} label="Authenticator (2FA)" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {
                      pinMode === "PIN" ?
                        <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                          <div>
                            <label className='my-2'>PIN</label>
                            <OTPInput
                              value={pin}
                              inputStyle="otp-input"
                              onChange={setPIN}
                              inputType='password'
                              numInputs={6}
                              renderSeparator={<span className="mr-1"></span>}
                              renderInput={(props) => <input {...props} />}
                            />
                            <label className='my-2'>Confirm PIN</label>
                            <OTPInput
                              value={cPin}
                              inputStyle="otp-input"
                              onChange={setCPin}
                              inputType='password'
                              numInputs={6}
                              renderSeparator={<span className="mr-1"></span>}
                              renderInput={(props) => <input {...props} />}
                            />
                            <Box display={"flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
                              <Button
                                onClick={() => {
                                  setLoading(true);
                                  UserAPI.VerifyPinCode(pin).then((s) => {
                                    Swal.fire({
                                      icon: "success",
                                      title: "Pin Setup",
                                      text: "Please Login again",
                                      showConfirmButton: true,
                                      confirmButtonText: "Close"
                                    }).then((x) => {
                                      if (x.isConfirmed) {
                                        clearToken();
                                      }
                                    })
                                  }).finally(() => {
                                    setLoading(false)
                                  })
                                }}
                                className='my-2' variant='contained' color={"primary"}
                                disabled={IsNullOrEmpty(pin) || IsNullOrEmpty(cPin) || pin !== cPin || pin.length !== 6 || cPin.length !== 6}
                              >
                                Set PIN
                              </Button>
                              <Button
                                onClick={() => {
                                  clearToken();
                                }}
                                className='my-2' variant='contained' color={"default"}
                              >
                                Close
                              </Button>
                            </Box>
                          </div>
                        </Box>
                        :
                        <>
                          <h5 className="text-center my-2 text-secondary">
                            Scan the QR Code from your authenticator App
                          </h5>
                          <p className="text-center mb-4">
                            <img src={QRCode} alt="QR Code" />
                          </p>
                          <TextField label="Secret" margin='dense' variant='outlined' value={QRCodePin} fullWidth InputProps={{
                            endAdornment: <InputAdornment position='end' onClick={() => {
                              navigator.clipboard.writeText(QRCodePin);
                              Toastr.info("copied to clipboard");
                            }
                            }>
                              <FileCopy />
                            </InputAdornment>
                          }} />
                          <VerifyPin onComplete={() => {
                            Swal.fire({
                              icon: "success",
                              title: "2FA Setup",
                              text: "Please Login again",
                              showConfirmButton: true,
                              confirmButtonText: "Close"
                            }).then((x) => {
                              if (x.isConfirmed) {
                                clearToken();
                              }
                            })

                          }}
                            onClose={() => {
                              clearToken();
                            }}
                          />
                        </>
                    }

                  </CardContent>
                </Card>
              </Container>
      }
    </>
  );
}
