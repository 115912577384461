import { Avatar, Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { AccountBalance, AccountBalanceWallet, AccountBalanceWalletRounded, CalendarToday, Close, Code, FileCopy, Fingerprint, Money, OpenInNew, Star, TransferWithinAStation, VerticalAlignBottom, VerticalAlignTop, Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from "toastr";
import _ from "underscore";
import { fetchWithdrawRequest } from '../../store/transaction/transaction.actions';
import { getWithdrawRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';
import { getTransactionUrl } from '../../helpers/utility';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function WithdrawContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchWithdrawRequest("Approved"))
    }, [])

    const { withdraws } = useSelector((state) => {
        return {
            withdraws: getWithdrawRequests(state)
        }
    }, []);

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(withdraws, x => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        });
    };


    const columns = [
        {
            name: 'createdAt',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Coins',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        actionRenderer()
    ];

    const datum = withdraws.map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format("lll"),
            coins: item.isToken ? (item.coins || item.amount) + " " + item.tokenSymbol : item.amount + " " + item.coinType,
            tokens: item.transactionTokens,
            transactionCharge: item.transactionCharge + ' ' + item.coinType,
            spentHash: item.spentHash,
            receiveHash: item.receiveHash,
            status: item.status,
        };
    });

    const options = {
        selectableRows: false,
        download: false,
        print: false
    };

    return (
        <div className='mb-4 pb-4'>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>
                    Withdraw Transaction <Close className='mt-1 float-right' onClick={() => setOpen(false)} />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className='bg-success'><Fingerprint /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={selectedItem.referenceNo} secondary={"Reference No"} />
                            <ListItemSecondaryAction>
                                <FileCopy color="secondary" onClick={() => {
                                    navigator.clipboard.writeText(selectedItem.referenceNo);
                                    Toastr.info("copied to clipboard");
                                }} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={selectedItem.transactionId} secondary={"Transaction ID"} />
                            <ListItemSecondaryAction>
                                <FileCopy color="secondary" onClick={() => {
                                    navigator.clipboard.writeText(selectedItem.transactionId);
                                    Toastr.info("copied to clipboard");
                                }} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className='bg-success'><Money /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={selectedItem.isToken ? (selectedItem.coins || selectedItem.amount) + " " + selectedItem.tokenSymbol : selectedItem.amount + " " + selectedItem.coinType} secondary={"Amount"} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className='bg-success'><CalendarToday /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={moment(selectedItem.createdAt).format("lll")} secondary={"Transaction Date"} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className='bg-success'><Star /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={selectedItem.status} secondary={"Status"} />
                        </ListItem>
                        {
                            selectedItem.transactionhash &&
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Code /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.transactionhash} classes={{
                                    primary: "overflow-text"
                                }} secondary={"Hash"} />
                                <ListItemSecondaryAction>
                                    <a target='_blank' href={getTransactionUrl("BNB") + selectedItem.transactionhash}>
                                        <OpenInNew color="primary" />
                                    </a>
                                </ListItemSecondaryAction>
                            </ListItem>
                        }
                    </List>
                </DialogContent>
            </Dialog>
            <TitleBar heading="Withdraw Transactions" secondary={""}
                icon={<AccountBalance />} />
            <div className={classes.root}>
                <MUIDataTable
                    title={"Withdraw Transactions List"}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div >
    );
}

