import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './assets/css/toastr.min.css';
import ProtectedRoute from './helpers/PrivateRoute';
import DashboardContainer from './pages/dashboard/Dashboard.Container';
import LoginContainer from './pages/login/Login.Container';
import ProfileContainer from './pages/profile/Profile.Container';
import ReferralContainer from './pages/referals/Referral.Container';
import ReferContainer from './pages/referees/Refer.Container';
import ReportContainer from './pages/reports/Report.Container';
import SignupContainer from './pages/signup/Signup.Container';
import WithdrawContainer from './pages/withdraw/Withdraw.Container';
import theme from './theme';
import MemberContainer from './pages/member/Member.Container';
import PlanContainer from './pages/plans/Plan.Container';
import OTPVerify from './pages/verify/otp-verify';
import BuyContainer from './pages/buy/Buy.Container';
import SellContainer from './pages/sell/Sell.Container';
import MyPlanContainer from './pages/plans/MyPlan.Container';
import ResetPassword from './pages/profile/reset';
import PayoutContainer from './pages/payout/Payout.Container';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<LoginContainer />} />
          <Route exact path="/signup" element={<SignupContainer />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <DashboardContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfileContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/verify"
            element={
              <ProtectedRoute>
                <OTPVerify />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/payouts"
            element={
              <ProtectedRoute>
                <PayoutContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/plans"
            element={
              <ProtectedRoute>
                <PlanContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/reset"
            element={
              <ResetPassword />
            }
          >
          </Route>
          <Route
            path="/members"
            element={
              <ProtectedRoute>
                <MemberContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/myplans"
            element={
              <ProtectedRoute>
                <MyPlanContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/buy-transactions"
            element={
              <ProtectedRoute>
                <BuyContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/sell-transactions"
            element={
              <ProtectedRoute>
                <SellContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/withdraws"
            element={
              <ProtectedRoute>
                <WithdrawContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/referals"
            element={
              <ProtectedRoute>
                <ReferralContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/refers"
            element={
              <ProtectedRoute>
                <ReferContainer />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <ReportContainer />
              </ProtectedRoute>
            }
          >
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <Snackbar style={{ width: "100%" }} className='bg-error' open={true} >
        <Alert style={{ width: "100%" }} severity="error">
          Potential fix is required : Node js running server is outdated. Please contact admin.
        </Alert>
      </Snackbar> */}
    </MuiThemeProvider >
  );
}
