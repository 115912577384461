import HttpHelper from '../../helpers/httpHelper';
require('dotenv').config();
const headerInfo = {};

class TransactionAPI {
    static async CreateBuyTransaction(req) {
        let url = process.env.REACT_APP_API_URL + '/swaps?code=' + req.code;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req.data);
    }

    static async CreateSellTransaction(req) {
        let url = process.env.REACT_APP_API_URL + '/swaps?mode=sell&code=' + req.code;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req.data);
    }

    static async CreateWithdrawTransaction(req) {
        let url = process.env.REACT_APP_API_URL + '/withdraws?code=' + req.code;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req.data);
    }

    static async GetBuyTransactions(status) {
        let url = process.env.REACT_APP_API_URL + '/swaps?type=Buy&status=' + status;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetSellTransactions(status) {
        let url = process.env.REACT_APP_API_URL + '/swaps?type=Sell&status=' + status;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetWithdrawTransactions(status) {
        let url = process.env.REACT_APP_API_URL + '/withdraws?status=' + status;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetPayoutTransactions(status) {
        let url = process.env.REACT_APP_API_URL + '/payouts';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async ProcessPayoutTransactions(req) {
        let url = process.env.REACT_APP_API_URL + `/payouts/withdraw?code=${req.code}&type=${req.type}&method=${req.method}`;
        return HttpHelper.httpRequest(url, 'POST', headerInfo);
    }
}

export default TransactionAPI;
