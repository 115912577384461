import { action } from "typesafe-actions";
import { PlanActionTypes } from "./plan.model";

export const fetchPlanRequest = (req) => action(PlanActionTypes.FETCH_PLAN_REQUEST, req);
export const fetchPlanSuccess = (res) => action(PlanActionTypes.FETCH_PLAN_SUCCESS, res);
export const fetchPlanFailure = (err) => action(PlanActionTypes.FETCH_PLAN_FAILURE, err);

export const fetchPlansRequest = (req) => action(PlanActionTypes.FETCH_PLANS_REQUEST, req);
export const fetchPlansSuccess = (res) => action(PlanActionTypes.FETCH_PLANS_SUCCESS, res);
export const fetchPlansFailure = (err) => action(PlanActionTypes.FETCH_PLANS_FAILURE, err);

export const fetchPlanMemberRequest = (req) => action(PlanActionTypes.FETCH_PLAN_MEMBER_REQUEST, req);
export const fetchPlanMemberSuccess = (res) => action(PlanActionTypes.FETCH_PLAN_MEMBER_SUCCESS, res);
export const fetchPlanMemberFailure = (err) => action(PlanActionTypes.FETCH_PLAN_MEMBER_FAILURE, err);

export const createPlanMemberRequest = (req) => action(PlanActionTypes.CREATE_PLAN_MEMBER_REQUEST, req);
export const createPlanMemberSuccess = (res) => action(PlanActionTypes.CREATE_PLAN_MEMBER_SUCCESS, res);
export const createPlanMemberFailure = (err) => action(PlanActionTypes.CREATE_PLAN_MEMBER_FAILURE, err);




