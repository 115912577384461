import { Box, Button, FormControlLabel, InputAdornment, Radio, TextField } from '@material-ui/core';
import { SwapVert } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWalletInformation } from '../../../store/user/user.selector';
import Swal from "sweetalert2";

export default function BuyRequest(props) {
    const { onBuy } = props;
    const [withdrawMode, setWithdrawMode] = useState("BNB");
    const [transferAmount, setTransferAmount] = useState(0);
    const [totalTokens, setTotalTokens] = useState(0);
    const [transactionCharge, setTransactionCharge] = useState(0);

    const { walletInfo } = useSelector((state) => {
        return {
            walletInfo: getWalletInformation(state),
        }
    }, []);

    let oneTokenPriceInUSD = parseFloat(walletInfo.tokenPrice.price);
    let oneUSDTokens = parseInt(1 / walletInfo.tokenPrice.price);
    let oneUSDBNB = parseInt(1 / walletInfo.coinPrice);
    let oneBNBPriceInUSD = parseFloat(walletInfo.coinPrice);
    let oneBNBTokens = parseInt(oneBNBPriceInUSD / walletInfo.tokenPrice.price);

    useEffect(() => {
        if (withdrawMode === "BNB") {
            setTransferAmount(walletInfo.coinBalance);
        }
        else {
            setTransferAmount(walletInfo.usdtTokenBalance);
        }
    }, [walletInfo, withdrawMode]);


    useEffect(() => {
        if (withdrawMode === "USDT") {
            setTotalTokens(parseInt(parseFloat(transferAmount) * oneUSDTokens));
        }
        else {
            setTotalTokens(parseInt(parseFloat(transferAmount) * oneBNBTokens));
        }
    }, [transferAmount, withdrawMode])

    useEffect(() => {
        let charge = 0;
        if (withdrawMode === "USDT") {
            charge = (oneTokenPriceInUSD * totalTokens * 0.01);
            charge = ((charge / parseFloat(walletInfo.coinPrice))).toFixed(8);
        }
        else {
            let transactionChargeUSDT = (oneTokenPriceInUSD * totalTokens * 0.01);
            charge = ((transactionChargeUSDT / parseFloat(walletInfo.coinPrice))).toFixed(8);
            setTransactionCharge(charge);
        }

        setTransactionCharge(charge);
    }, [totalTokens, withdrawMode]);

    const handleBuy = () => {
        let error = "";
        if (withdrawMode === "USDT" && parseFloat(transferAmount) < 10) {
            error = "Minimum of 10 USDT should be spend to buy tokens";
        }
        else if (withdrawMode === "USDT" && parseFloat(transferAmount) >= 10) {
            if (parseFloat(walletInfo.usdtTokenBalance) < parseFloat(transferAmount)) {
                error = "You don't have sufficient USDT balance to buy tokens";
            }
            else if (parseFloat(walletInfo.coinBalance) < parseFloat(transactionCharge)) {
                error = "You don't have sufficient BNB balance to process";
            }
        }
        else {
            let totalBNBInUSD = parseFloat(transferAmount) * oneBNBPriceInUSD;
            if (parseFloat(totalBNBInUSD) < 10) {
                error = `Minimum of ${(10 / oneBNBPriceInUSD).toFixed(8)} BNB should be spend to buy tokens`;
            }
            else if (parseFloat(walletInfo.coinBalance) < parseFloat(transferAmount)) {
                error = "You don't have sufficient BNB balance to buy tokens";
            }
        }

        if (error) {
            Swal.fire({
                icon: "error",
                title: "Validation",
                text: error
            })
        }
        else {
            onBuy(withdrawMode, parseFloat(transferAmount), parseFloat(Math.floor(totalTokens)));
        }
    }

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <div>
                <FormControlLabel
                    value="start"
                    control={<Radio color="primary" checked={withdrawMode === "BNB"} />}
                    label={`Using BNB (${walletInfo.coinBalance})`}
                    labelPlacement="end"
                    onChange={() => {
                        setWithdrawMode(withdrawMode === "USDT" ? "BNB" : "USDT");
                    }}
                />
                <FormControlLabel
                    value="start"
                    control={<Radio color="primary" checked={withdrawMode === "USDT"} />}
                    label={`Using USDT (${walletInfo.usdtTokenBalance})`}
                    labelPlacement="end"
                    onChange={() => {
                        setWithdrawMode(withdrawMode === "USDT" ? "BNB" : "USDT");
                    }}
                />
            </div>
            <TextField
                fullWidth
                className='my-1'
                margin='dense'
                variant='outlined'
                value={transferAmount}
                helperText={withdrawMode === "USDT" ?
                    `You can spend maximum of ${parseFloat(walletInfo.usdtTokenBalance).toFixed(3)} USDT` :
                    `You can spend maximum of ${(walletInfo.coinBalance - transactionCharge).toFixed(8)} BNB`
                }
                onChange={(e) => {
                    let curValue = e.target.value;
                    if (!curValue) {
                        curValue = 0;
                    }
                    setTransferAmount(curValue);
                }}
                placeholder='Enter Value'
                label={"Amount"}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        {withdrawMode}
                    </InputAdornment>
                }}
            />
            <SwapVert fontSize='large' />
            <TextField
                fullWidth
                margin='dense'
                className='my-1'
                variant='outlined'
                value={totalTokens}
                type="phone"
                disabled
                placeholder='Tokens'
                label="Tokens"
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        {walletInfo.tokenPrice.symbol}
                    </InputAdornment>
                }}
            />
            <TextField
                fullWidth
                margin='dense'
                className='my-1'
                variant='outlined'
                value={transactionCharge}
                type="phone"
                disabled
                placeholder='Tokens'
                label="Transaction Charge"
                InputProps={{
                    endAdornment: <InputAdornment position='end'>
                        BNB
                    </InputAdornment>
                }}
            />
            <Button className='mt-2' onClick={() => {
                handleBuy()
            }} variant='contained' color="primary" fullWidth>
                Buy Tokens
            </Button>
        </Box>
    );
}

