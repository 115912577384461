export const API_URL = process.env.REACT_APP_API_URL;

export function getLoginUrl() {
    return API_URL + '/login';
}

export function get2FASetup() {
    return API_URL + '/members/2fa/setup';
}

export function getPinSetup() {
    return API_URL + '/members/pin/setup';
}

export function getSendEmailOTP() {
    return API_URL + '/members/set/email/otp';
}

export function getSendPhoneOTP() {
    return API_URL + '/members/set/phone/otp';
}

export function setSendEmailOTP(otp) {
    return API_URL + '/members/verify/email/otp?otp=' + otp;
}

export function setSendPhoneOTP(otp) {
    return API_URL + '/members/verify/phone/otp?otp=' + otp;
}

export function verify2FAUrl() {
    return API_URL + '/members/2fa/verify';
}

export function getMemberDetailUrl(memberId) {
    return API_URL + '/member/referral/' + memberId;
}

export function createWhitelistUrl(code) {
    return API_URL + '/whitelist?code=' + code;
}

export function getStatUrl() {
    return API_URL + '/members/wallet/stats';
}

export function getForgotPasswordURI(email) {
    return API_URL + '/members/password/forgot?email=' + email;
}

export function getChangePasswordURI() {
    return API_URL + '/members/password/update';
}

export function changeNomineeURI(code) {
    return API_URL + '/members/nominee';
}

export function changeto2FAUrl(code) {
    return API_URL + '/members/change/2FA?code=' + code;
}

export function changetoPINUrl(code) {
    return API_URL + '/members/change/PIN?code=' + code;
}

export function getActiveIDUrl() {
    return API_URL + '/report/active';
}

export function getReferralReportUrl() {
    return API_URL + '/report/referal';
}

export function getRewardReportUrl() {
    return API_URL + '/report/reward';
}