export const TransactionActionTypes = {
  FETCH_BUY_REQUEST: "@@Transaction/FETCH_BUY_REQUEST",
  FETCH_BUY_SUCCESS: "@@Transaction/FETCH_BUY_SUCCESS",
  FETCH_BUY_FAILURE: "@@Transaction/FETCH_BUY_FAILURE",

  FETCH_PAYOUT_REQUEST: "@@Transaction/FETCH_PAYOUT_REQUEST",
  FETCH_PAYOUT_SUCCESS: "@@Transaction/FETCH_PAYOUT_SUCCESS",
  FETCH_PAYOUT_FAILURE: "@@Transaction/FETCH_PAYOUT_FAILURE",

  CREATE_BUY_REQUEST: "@@Transaction/CREATE_BUY_REQUEST",
  CREATE_BUY_SUCCESS: "@@Transaction/CREATE_BUY_SUCCESS",
  CREATE_BUY_FAILURE: "@@Transaction/CREATE_BUY_FAILURE",

  FETCH_SELL_REQUEST: "@@Transaction/FETCH_SELL_REQUEST",
  FETCH_SELL_SUCCESS: "@@Transaction/FETCH_SELL_SUCCESS",
  FETCH_SELL_FAILURE: "@@Transaction/FETCH_SELL_FAILURE",

  CREATE_SELL_REQUEST: "@@Transaction/CREATE_SELL_REQUEST",
  CREATE_SELL_SUCCESS: "@@Transaction/CREATE_SELL_SUCCESS",
  CREATE_SELL_FAILURE: "@@Transaction/CREATE_SELL_FAILURE",

  FETCH_WITHDRAW_REQUEST: "@@Transaction/FETCH_WITHDRAW_REQUEST",
  FETCH_WITHDRAW_SUCCESS: "@@Transaction/FETCH_WITHDRAW_SUCCESS",
  FETCH_WITHDRAW_FAILURE: "@@Transaction/FETCH_WITHDRAW_FAILURE",

  CREATE_WITHDRAW_REQUEST: "@@Transaction/CREATE_WITHDRAW_REQUEST",
  CREATE_WITHDRAW_SUCCESS: "@@Transaction/CREATE_WITHDRAW_SUCCESS",
  CREATE_WITHDRAW_FAILURE: "@@Transaction/CREATE_WITHDRAW_FAILURE",

  PROCESS_PAYOUT_REQUEST: "@@Transaction/PROCESS_PAYOUT_REQUEST",
  PROCESS_PAYOUT_SUCCESS: "@@Transaction/PROCESS_PAYOUT_SUCCESS",
  PROCESS_PAYOUT_FAILURE: "@@Transaction/PROCESS_PAYOUT_FAILURE",
};

